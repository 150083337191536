import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeSettingsService } from 'src/app/_layout/settings/theme-settings.service';
import { MenuSettingsService } from 'src/app/_layout/settings/menu-settings.service';
import { AuthserviceService } from 'src/app/core/services/authservice.service';
import { HelpandtipsService } from 'src/app/services/helpandtips/helpandtips.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import {ToggleIconService} from "../../_services/toggleIcon.service";
import {AppConstants} from "../../_helpers/app.constants";
import { environment } from '../../../environments/environment';

import { ReskinningService } from 'src/app/_services/reskinning.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthserviceService as ClientService } from "src/app/services/auth/authservice.service";
import { LogsService } from 'src/app/services/logs/logs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

  rskBodyTextColor = this.ReskinningService.dashboard_text_color;
  rskscrollbgcolor = this.ReskinningService.rskscrollbgcolor;
  rskBodyText = this.ReskinningService.rskBodyText;
  rskHomePageButton1 = this.ReskinningService.rskHomePageButton1;
  rskHomePageButton2 = this.ReskinningService.rskHomePageButton2;
  rskHomePageButton1_url = this.ReskinningService.rskHomePageButton1_url;
  rskHomePageButton2_url = this.ReskinningService.rskHomePageButton2_url;

  chatPopup: boolean = false;
  myWindow = null;
  requestList:any=[];
  support_user;
  renewalDate:Date;
  private _themeSettingsConfig: any;
  public _menuSettingsConfig: any;
  public customercompanylogo;
  public __themeSettingsConfig: any;
  showINMobile:boolean=false;
  projectName = environment.projectName;
  userData: any;
  @ViewChild('home_icons') private myScrollContainer: ElementRef;
  @ViewChild('alertPopup') private alertPopup:ElementRef;
   isBu:boolean = false;
   professioanlData:any;
  constructor(
    public router: Router,
    private _renderer: Renderer2,
    public AuthserviceService: AuthserviceService,
    private helpService : HelpandtipsService,

    private toggleService: ToggleIconService,

    private deviceDetectorService: DeviceDetectorService,
    private ReskinningService:ReskinningService,
    private modal:NgbModal,
    private clientService: ClientService,
    private logs:LogsService) {
    super();
    this.baseService.changeValue(false);
   //this.router.navigateByUrl("directseller/home")
   var name = "MyHome Page View by " +  this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    this.addLogData("MyHome Page View",name);

   }

  ngOnInit() {

    this.toggleService.iconStatus.next(true);
    let isMobile = this.deviceDetectorService.isMobile();
    let isTablet = this.deviceDetectorService.isTablet();

    //

    // if(isMobile || isTablet ) {
    //        this.showINMobile=true;
    //
    // }
    // else {
    //
    //   this.showINMobile=false;
    // }

    if (window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {

      this.showINMobile = true;
    } else {
      this.showINMobile = false;
    }


    if (this.router.url.indexOf('/guest/')<0 && this.router.url.indexOf('/notauthorised')<0) {
      this.helpService.getSupportRequest().subscribe((data)=>{
        if(data.status == true){
          this.requestList = data.data;
        }
       });
      }
    var userdata=JSON.parse(localStorage.getItem("UserData"));
    this.userData = JSON.parse(localStorage.getItem("UserData"));
    let isLoginPage=localStorage.getItem('isLoginPage');
    if(isLoginPage=='true'){
      this.captureDsLogs(this.userData)
    }
    this.isBu = userdata.is_bu;
    if(userdata) {
    this.support_user = userdata.support_user;
    this.customercompanylogo=userdata.customer_company_logo;
      if( userdata.role_id!==77) {
          this._menuSettingsConfig = {
            horizontal_menu: {
              items: [
                // {
                //   title: 'Home',
                //   icon: 'assets/images/svg/home.svg',
                //   page: '/directseller/market',
                //   code: 'MRL',
                // },
                {
                  title: 'Home',
                  icon: 'assets/images/svg/home.svg',
                  homeLink: true,
                  page: '/directseller/home',
                  code: 'MRL',
                },
                {
                  title: 'Market',
                  icon: 'assets/custom/images/marketIcon.svg',
                  page: '/directseller/market',
                  code: 'MRL',
                },
                {
                  title: 'Get Social',
                  icon: 'assets/custom/images/getSocialIcon.svg',
                  page: '/directseller/social',
                  code: 'SOL',
                },
                {
                  title: 'Quick Message',
                  icon: 'assets/custom/images/mb.png',
                  page: '/directseller/sms/dashboard',
                  code: 'SMB',
                },

                {
                  title: 'Replenishment',
                  icon: 'assets/custom/images/replenishmentIcon.svg',
                  page: '/directseller/replenishment',

                  code: 'PRL',


                },
                {
                  title: 'Cross-sell',
                  icon: 'assets/custom/images/corssSellIcon.svg',
                  page: '/directseller/crosssell',

                  code: 'PRL',


                },
                {
                  title: 'My Dashboard',
                  icon: 'assets/custom/images/myDashIcon.svg',
                  page: '/directseller/mydashboard',
                  code: 'DSL',



                },
                {
                  title: 'Messenger',
                  icon: 'assets/custom/images/communityIcon.svg',
                  page: '/directseller/messenger/ds-messenger',

                  badge: {

                  },
                  code: 'MGL',



                },
                {
                  title: 'Shop Live',
                  icon: 'assets/custom/images/shop_icon.svg',
                  page: '/directseller/shop-live',
                  code: 'SVL',
                  },
                  {
                    title: 'Webinar',
                    icon: 'assets/custom/images/webinar.svg',
                    page: '/directseller/webinar/dashboard',
                    code: 'SVL',
                    },






              ]
            }

          };
      } else {
          this._menuSettingsConfig = {
            horizontal_menu: {
              items: [
                {
                  title: 'Hosted Event',
                  icon: 'assets/custom/images/1.png',
                  page: '/host',
                  code: 'MEL',
                }

              ]
            },
            vertical_menu: {
              items: [
                {
                  title: 'Hosted Event',
                  icon: 'assets/custom/images/1.png',
                  page: '/host',
                  code: 'MEL',
                }

              ]
            }

          };
      }


    if(userdata.bundle_name) {
    var newArray=[];
    this._menuSettingsConfig.horizontal_menu.items.forEach(element => {
    Object.keys(userdata.user_bundle.nav).forEach(function(key) {
      if (( (key == 'MSL' || key == 'MEL') && userdata.user_bundle.nav[key]==1) && element.code == 'MRL') {
        const matches = newArray.filter(obj => obj.title === element.title);
        if (!matches.length) newArray.push(element);
       }
       else if ((key == 'SID' && userdata.user_bundle.nav[key]==1) && element.code == 'SOL') {
         newArray.push(element);
       }
       else if (element.code == key && userdata.user_bundle.nav[key] ==1) {
         newArray.push(element);
       }
    });
   });
  this._menuSettingsConfig.horizontal_menu.items = newArray;
  }
    // Particullary coded for independent-branch start -----------
     if(!this.isBu && (this.userData?.user_ds_id!=1528 && this.userData?.user_ds_id!=1204 && this.userData?.user_ds_id!=1210 && this.userData?.user_ds_id!=1209 && this.userData?.user_ds_id!=1211 && this.userData?.user_ds_id!=1532 )){
    this._menuSettingsConfig.horizontal_menu.items = this._menuSettingsConfig.horizontal_menu.items.filter((ele:any)=>{
    return  ele.code != 'PRL';
    })
  }
    // Particullary coded for independent-branch ends -----------

    }
  }

  openIframe() {
    this.chatPopup = !this.chatPopup;
  }

  openMMWindow() {
    //this.myWindow = window.open('https://margo-imagine.herokuapp.com/', 'marGo - Imagine', 'top=100,left=300,width=1000,height=500,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no');
  }

  closeMMWindow() {
    if (this.myWindow) {
      this.myWindow.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {

      this.showINMobile = true;
    } else {
      this.showINMobile = false;
    }
  }

  setActivePage(value,page) {

    if (page === 'mycontact') {
      if (sessionStorage.getItem('add-guest')) {
        sessionStorage.removeItem('add-guest');
      }
    } else {
      localStorage.setItem('Page', value);
      localStorage.setItem('oldPage', value);
    }

  }

  goToSegment(child, target:HTMLElement) {
    if (child && child?.homeLink) {
     // target.scrollIntoView();
      try {
        setTimeout(() => {
          this.myScrollContainer.nativeElement.scrollIntoView();
          // alert(this.myScrollContainer.nativeElement.scrollTop);
          // console.log(this.myScrollContainer.nativeElement);
        }, 0)

      } catch (err) {
      }
    }
  }

  //  ##################  On Destroy  ####################################  //

  ngOnDestroy() {
    this.toggleService.iconStatus.next(false);
  }
  ngAfterViewInit(){
    let subscriptionExpDays=this.userData?.subscription_notification_days || 1;
    if(this.userData?.sub_id && this.userData.is_bu==false){
      let currentDate=new Date();
      let expiryDate=new Date(this.userData?.sub_end_date)
      let threeDaysSub=new Date(new Date(expiryDate).setDate(expiryDate.getDate() - Number(subscriptionExpDays)));
      if( currentDate >= threeDaysSub && currentDate < expiryDate){
        let bodyTag=document.querySelector('body')
        this.renewalDate = expiryDate;
        this.getSubscriptiondropdown()
        bodyTag.classList.add('no-bodyscroll')
        this.modal.open(this.alertPopup,{
          backdrop:true,
          scrollable:true
        }).result.then(
          (result) => {
            bodyTag.classList.remove('no-bodyscroll')
          },
          (reason) => {
            bodyTag.classList.remove('no-bodyscroll')
          }
        );
      }
    }
  }
  getSubscriptiondropdown() {
    this.ngxSpinnerService.show();
    this.clientService
      .getHomeSubscriptionList(this.userData?.user_customer_id, 10,0)
      .subscribe(
        (res) => {
          this.ngxSpinnerService.hide();
          if (res.status) {
            let subData = res.data.rows;
            if (subData && subData?.length > 0) {
                if(this.userData.subscription_type=='14-Days'){
                  this.professioanlData = subData.find(
                    (sub: any) => sub.slug_title==='professional'
                  );
                }else{
                  this.professioanlData = subData.find(
                    (sub: any) => sub.id===this.userData?.sub_id
                  );
                }
            } else {
              this.professioanlData={}
            }
          } else {
            this.professioanlData={}
          }
        },
        (error) => {
          this.professioanlData={}
          this.ngxSpinnerService.hide();
        }
      );
  }
  updateYearlyValue(data:any){
    if(data){
      let subTotal = +data.price * 12;
      let discountTotal =
          (Number(subTotal) / 100) *
          Number(data.yearly_discount);
      let gstTotal =
          (Number(subTotal - discountTotal) / 100) *
          Number(data.gst_rate);
      let totalPay =
          Number(subTotal) + Number(gstTotal) - discountTotal;
      let serviceFee = 0.30+(totalPay * 0.029);
      totalPay = totalPay + serviceFee;
      return totalPay.toFixed(2)
    }
    return '0.00'
   }
   captureDsLogs(userData:any){
    let body={
      "accessed_url" : '/login',
      "log_name" :'Sign In | marGo Direct Selling Software',
      "customer_cookies" :(new Date).getTime(),
      "referral_code" :'',
      "sub_id" : userData?.sub_id || '',
      "plan_type" : userData.subscription_type,
      "user_id":userData.ds_id
    }
    this.logs.captureWebsiteDsLogs(body,userData.client_id).subscribe({
      next:(res:any)=>{
        localStorage.removeItem('isLoginPage')
      }
    });
  }
}

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,



  //chatURI: 'https://margo-chat-qa.herokuapp.com/',



 //chatURI:'https://chat.margo.me/',




 apiUrl:'https://club-backend.margo.me/',
//apiUrl:'http://localhost:8088/',


 directSellerURL : 'https://shop.margo.me/',
 chatURI:'https://club-chat.margo.me/',
 chatDSURI:'https://club-chat-ds-api.margo.me/',
//chatDSURI:'http://localhost:5000/',



 sliveURL: 'https://shoplive2.supportadita.in/',
 sliveURLOneToMany: 'https://shoplive2.supportadita.in/',
 manyToManyInstanceId: 'i-035d1b481d9c7548a',
 oneToManyInstanceId: 'i-086abf5bce297e0bf',


  s3URL:'https://margous-me-2.s3.amazonaws.com/',



companyId:175,
canvaApiKey: {


    prod: 'MCCsww7ZMo0lmtkxT0SPiE7P',
    test: 'y9r0HVKnOjmZhK97phQA2-1t'
  },
  scrollLimit: 12,
  awsBucket: '',
  projectName: 'marGo',
};
